import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import { store } from './redux/store/index.js';
import { Auth0Provider } from '@auth0/auth0-react'
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API || "http://localhost:3001/" ;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <Auth0Provider
          domain = {process.env.REACT_APP_DOMAINAUTH0}
          clientId= {process.env.REACT_APP_CLIENTID}
          redirectUri={window.location.href} >
        <App />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);

