import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPropById, latlong } from "../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { BiBath, BiBed  } from "react-icons/bi";
import { AiOutlineBorder  } from "react-icons/ai";
import { FaBorderAll } from "react-icons/fa";
import { MdOutlineMeetingRoom } from "react-icons/md";
import { GiHomeGarage, GiCancel } from "react-icons/gi";
import { useNavigate } from "react-router-dom"; 
import { RiArrowGoBackFill } from "react-icons/ri";

// Import Swiper styles
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./Style.css";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

const Details = () => {
  const { id } = useParams();
  const propsId = useSelector((state) => state.propsId);
  var lat = useSelector((state) => state.lat);
  var long = useSelector((state) => state.long);
  const image = useSelector((state) => state.image);
  const dispatch = useDispatch();
  const [thumbsSwiper, setThumbsSwiper] = useState();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(getPropById(parseInt(id)));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(latlong(propsId.ubicacion));
  }, [dispatch, propsId.ubicacion]);

  return (
    <div className="w-full mt-14 pt-2 2xl:w-full px-4 md:px-8 lg:px-16 2xl:grid xl:grid lg:grid grid-cols-2 bg-texto-light dark:bg-main-dark">
      <div className="">
        <Swiper
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="2xl:h-[70%] 2xl:w-[100%] h-[50%] w-[100%] mt-4 xl:h-[60%] xl:w-[100%] lg:h-[65%] lg:w-[100%]"
        >
          {image.map((e) => (
            <SwiperSlide >
              <img src={e}  alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={20}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper w-[100%] mt-2"
        >
          {image.map((e) => (
            <SwiperSlide >
              <img src={e}  alt="" />
            </SwiperSlide>
          ))}
      <h3 className="2xl:text-4xl xl:text-2xl lg:text-4xl text-xl mt-2  text-[#FFFFFF]">
            {propsId.titulo}
          </h3>
        </Swiper>
      </div>
      <div className=" px-4 md:px-8 lg:px-16 mt-4 bg-texto-light">
        <h3 className=" w-[100%] text-left 2xl:text-3xl  text-[#FFFFFF]">Ubicación:</h3>
        {/* <MapContainer
          center={[lat, long]}
          zoom={20}
          style={{ height: "200px", width: "100%" }}
        >
          <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={[lat, long]}></Marker>
        </MapContainer> */}
        <div className="h-[20%] md:h-[300px] xl:h-[250px] 2xl:pt-6  2xl:h-[300px] 2xl:w-[100%]">
          <iframe
            title="mapa"
            width="100%"
            height="100%"
            src={`https://maps.google.com/maps?q=${lat},${long}&hl=es;z=14&output=embed`}
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="grid grid-cols-2">
          <div>
          <h3 className="my-4 w-[100%] text-left 2xl:text-3xl text-[#FFFFFF]">Descripción:</h3>
          <ul className="grid grid-cols-1 gap-4 justify-center 2xl:text-xl items-center  ">
            <li className="mr-4 text-main-light flex"><BiBath size={20} className="mr-3"/>{propsId.baños}   Baños</li>
            <li className="mr-4 text-main-light flex"><MdOutlineMeetingRoom size={20} className="mr-3"/>{propsId.ambientes} Ambientes</li>
            <li className="mr-4 text-main-light flex"><BiBed size={20} className="mr-3"/>{propsId.dormitorios} Dormitorios</li>
            <li className="mr-4 text-main-light flex"><AiOutlineBorder size={20} className="mr-3"/>{propsId.superficie} m² Terreno</li>
            <li className="mr-4 text-main-light flex"><FaBorderAll size={20} className="mr-3"/>{propsId.superficiecubierta} m² Cubiertos</li>
            {propsId.cochera == true ?   
            <li className="mr-4 text-main-light flex"><GiHomeGarage size={20} className="mr-3"/>Garage</li>
            :<></>  
          }
          </ul>
          </div>
          <div className="pt-7 lg:pt-14 2xl:pt-14">
          <form method="POST" action="https://formsubmit.co/3bf9a9777c6e548cbda39778bfd27077">
            <div className="mb-3 xl:mb-2">
              <input
                type="text"
                id="nombre"
                name="nombre"
                className="p-1 w-full"
                placeholder="Nombre y Apellido"
              />
            </div>
            <div className="mb-3 xl:mb-2">
              <input
                type="email"
                id="email"
                name="email"
                className="p-1 w-full"
                placeholder="Email"
              />
            </div>
            <div className="mb-2 xl:mb-2">
              <textarea
                id="mensaje"
                name="mensaje"
                value={`Hola, quisiera consultar el precio de ${propsId.titulo}`} 
                className="p-1 w-full resize-none"
              ></textarea>
            </div>
            <button
              type="submit"
              className="text-[#105167] bg-[#E0FBFC] rounded-full px-2 py-2 xl:px-6 xl:py-2 mt-3 2xl:text-2xl mx-auto flex items-center"
            >
              CONSULTAR PRECIO
            </button>
          </form>
          </div>
        </div>
      </div>
      <button className="fixed bottom-4 right-4 bg-texto-light text-main-light py-2 px-4 rounded-full shadow-md hover:bg-texto-dark z-10" onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
    </div>
  );
};

export default Details;
