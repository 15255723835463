import React from "react";
import servicios from "../assets/servicios.webp";
import alquilerimg from "../assets/imgAlquiler.webp";
import adminimg from "../assets/imgAdm.webp";
import atencionimg from "../assets/imgAtencion.webp";
import compraimg from "../assets/imgCompra.webp";
import tasacionimg from "../assets/imgTasa.webp";
import { Link as RouterLink } from "react-router-dom";

const Servicios = () => {
  return (
    <div
  name="serv"
  style={{ backgroundImage: `url(${servicios})` }}
  className="w-full min-h-screen md:h-screen pt-14 bg-main-light dark:bg-main-dark text-texto-light dark:text-texto-dark"
>
<h1 className="text-4xl md:text-5xl lg:text-6xl  2xl:text-8xl text-center p-2 md:p-6 lg:p-0">SERVICIOS</h1>
  <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center items-center">
    
    <div className="flex flex-col items-center bg-[#cfcfcf] bg-opacity-60 mx-auto w-full md:w-[370px] h-[220px] md:h-[370px] xl:w-[250px] xl:h-[250px]">
      <RouterLink to="/Alquiler">
        <div className="text-center">
          <img
            src={alquilerimg}
            alt="/"
            className="w-full h-full object-cover"
          />
          <div className=" text-center">
            <h6 className="text-center rounded-lg px-4  m-2 font-bold text-lg md:text-2xl">
              Alquiler de propiedades
            </h6>
          </div>
        </div>
      </RouterLink>
    </div>
    <div className="flex flex-col items-center bg-[#cfcfcf] bg-opacity-60 mx-auto w-full md:w-[370px] h-[220px] md:h-[370px] xl:w-[250px] xl:h-[250px]">
      <RouterLink to="/Venta">
        <div className="text-center">
          <img src={compraimg} alt="/"  className="max-w-full max-h-full object-cover"/>
          <div className=" text-center">
            <h6 className="text-center rounded-lg px-4  m-2 font-bold text-lg md:text-2xl">
            Compra/Venta de propiedades
            </h6>
          </div>
        </div>
      </RouterLink>
    </div>
    <div className="flex flex-col items-center bg-[#cfcfcf] bg-opacity-60 mx-auto w-full md:w-[370px] h-[220px] md:h-[370px] xl:w-[250px] xl:h-[250px]">
      <RouterLink to="/Administracion">
        <div className="text-center">
          <img src={adminimg} alt="/"  className="max-w-full max-h-full object-cover"/>
          <div className=" text-center">
            <h6 className="text-center rounded-lg px-4  m-2 font-bold text-lg md:text-2xl">
            Administración de consorcios
            </h6>
          </div>
        </div>
      </RouterLink>
    </div>
    <div className="flex flex-col items-center bg-[#cfcfcf] bg-opacity-60 mx-auto w-full md:w-[370px] h-[220px] md:h-[370px] xl:w-[250px] xl:h-[250px]">
      <RouterLink to="/Contacto">
        <div className="text-center">
          <img src={atencionimg} alt="/" className="max-w-full max-h-full object-cover" />
          <div className=" text-center">
            <h6 className="text-center rounded-lg px-4  m-2 font-bold text-lg md:text-2xl">
            Atención personalizada
            </h6>
          </div>
        </div>
      </RouterLink>
    </div>
    <div className="flex flex-col items-center bg-[#cfcfcf] bg-opacity-60 mx-auto w-full md:w-[370px] h-[220px] md:h-[370px] xl:w-[250px] xl:h-[250px]">
      <RouterLink to="/Tasacion">
        <div className="text-center">
          <img src={tasacionimg} alt="/" className="max-w-full max-h-full object-cover" />
          <div className=" text-center">
            <h6 className="text-center rounded-lg px-4  m-2 font-bold text-lg md:text-2xl">
            Tasación de propiedades
            </h6>
          </div>
          
        </div>
      </RouterLink>
    </div>
  </div>
</div>
  );
};

export default Servicios;
