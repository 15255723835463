import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { dashId } from "../redux/action/index.js"

const DashPreviewCard = ({data}) => {
    const dispatch = useDispatch()
    const handleId = (e) => {
        dispatch(dashId(data.id))
    }
    console.log("DATA-id", data)
    if (data) {
        return (
            <div className='p-12 text-3xl'>

                 <button id={data?.id} onClick={(e) => {handleId(e)}} >

                    <div className='bg-main-light dark:bg-main-dark text-center flex flex-col gap-1 mt-1'>
                        <img src={data.image[0]} alt={data.id} />
                        <h2 className=' text-main-dark dark:text-main-light text-base font-bold'>{`${data.titulo}`}</h2>
                    </div>
                    </button>
            </div>
        )
    }
}

export default DashPreviewCard