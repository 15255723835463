import './App.css';
import Rutas from "./routes/Rutas"


function App() {
  return (
    
    <div className="App bg-main-light dark:bg-main-dark min-h-screen transition-all duration-150">
      <Rutas/>
    </div>
    
  );
}

export default App;
