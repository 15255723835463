import React, { useState } from "react"
import {  useEffect } from "react"
import FormMod from "./FormMod";
import { useDispatch, useSelector } from "react-redux";
import DashPropiedades from "./DashPropiedades";
import { DashDelDetail, getAllProps, getPropById } from "../redux/action";

const ModificarPropiedad = () => {
  const dispatch = useDispatch()
  const id = useSelector(state => state.dashid)
  const props = useSelector(state => state.propsId)
  const handleVolver = () => {
    dispatch(DashDelDetail())
  }
  useEffect(()=> {
    dispatch(getAllProps())
    dispatch(getPropById(id))
  },[dispatch, id])
  
  console.log("ID", id)
  return (
    <div>
        <div className="w-[1000px] h-[939px] ">
            
            {props.titulo ? <div className="mt-[200px] w-[900px] ml-[30px]">
                <button onClick={handleVolver}>Volver</button>
                <FormMod props={props} />
                </div>
                :<div className=" "><DashPropiedades /></div> 
}
        </div>
    </div>
  )
}

export default ModificarPropiedad