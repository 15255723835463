import React from "react";
import adm from "../assets/Administracion.webp";
import { useNavigate } from "react-router-dom"; 
import { Link as RouterLink } from "react-router-dom";
import { RiArrowGoBackFill } from "react-icons/ri";

const Administración = () => {
  let navigate = useNavigate();
  return (
    <div
      name="adm"
      className="w-full h-screen text-texto-light bg-main-light  dark:text-texto-dark dark:bg-main-dark flex flex-col justify-center items-center"
    >
      <div className="relative inline-block text-center w-full h-[60%]">
        <img className="h-[100%] w-full" src={adm} alt="fondo" />
        <div className="text-[#ffffff] text-3xl md:text-7xl 2xl:text-8xl  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:text-center">
          <br /> ADMINISTRACIÓN <br />
        </div>
      </div>
      <div className="relative inline-block text-center w-full h-[5%]">
        <div className="bg-texto-light h-[8px] w-[60%]  absolute  top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "></div>
      </div>
      <div className="dark:bg-main-dark h-[25%] w-[90%] pt-6 flex flex-col justify-center items-center">
        <p className="text-[15px] md:text-3xl pb-4 md:pb-8  dark:bg-main-dark text-center">
          Administramos consorcios conforme a las leyes vigentes, de manera
          profesional y personal en San Fernando del Valle de Catamarca.
          Verificamos el correcto funcionamiento de las instalaciones, junto con
          liquidación de expensas de forma clara y simple.
        </p>
      <RouterLink to="/Contacto">
        <button className="bg-[#105167] text-[#E0FBFC] 2xl:text-5xl rounded-full px-4 py-2 xl:px-6 xl:py-2 mt-3 mx-auto flex items-center">
        Contactar
      </button>
      </RouterLink>
      </div>
      <button className="fixed bottom-4 right-4 bg-texto-light text-main-light py-2 px-4 rounded-full shadow-md hover:bg-texto-dark z-10" onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
    </div>
  );
};

export default Administración;
