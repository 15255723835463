import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import alq from "../assets/Alquiler.webp";
import { getProps } from "../redux/action/index.js";
import { useNavigate } from "react-router-dom"; 
import { RiArrowGoBackFill } from "react-icons/ri";
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link as RouterLink } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Alquiler = () => {
  // const img = [casa, casa2, casa3,casa4,casa5,casa6,casa7];
  const props = useSelector((state) => state.props);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    // if (!props[0]) {
      dispatch(getProps());
    // }
  }, [dispatch]);

  console.log(
    "propsA",
    props.map((el) => el.id)
  );

  return (
    <div
  name="Alq"
  className="w-full h-screen 2xl:w-full 2xl:h-screen md:h-screen lg:h-screen text-texto-light bg-main-light dark:text-texto-dark dark:bg-main-dark flex flex-col justify-center items-center"
>
  <div className="relative inline-block text-center w-full h-[50%] xl:h-[50%] 2xl:h-[60%]">
    <img className="h-full w-full object-cover" src={alq} alt="fondo" />
    <div className="text-[#ffffff] text-4xl xl:text-5xl 2xl:text-8xl lg:text-5xl absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/3 lg:text-center">
      ALQUILER
    </div>
    <div className="text-[#E0FBFC] text-sm xl:text-xl 2xl:text-5xl 2xl:[pt-8] w-[90%] xl:w-[80%] 2xl:w-[80%] absolute 2xl:top-2/3 xl:top-56 top-[240px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:text-center">
      Alquilar una propiedad puede ser una preocupación constante. Sin embargo,
      nosotros nos encargamos de aliviar ese estrés, permitiéndote enfocarte
      únicamente en recibir tu dinero a fin de mes. Además, si estás en busca de
      un lugar para habitar, nos comprometemos a encontrar la propiedad ideal que
      se ajuste perfectamente a tus necesidades.
      <br />
      <RouterLink to="/Contacto">
      <button className="text-[#105167] bg-[#E0FBFC] rounded-full px-4 py-2 xl:px-6 xl:py-2 mt-3 mx-auto flex items-center">
        Contactar
      </button>
      </RouterLink>
    </div>
  </div>
  <div className="w-[90%] h-[38%] xl:h-[50%] 2xl:h-[40%] pt-4">
    <Swiper
      modules={[Navigation, Autoplay]}
      slidesPerView={3}
      autoplay={{ delay: 5000 }}
      navigation={true}
      spaceBetween={30}
    >
      {props &&
        props.map((el, index) => (
          <SwiperSlide key={index}>
            <div className="grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1">
              <RouterLink to={`/Alquiler/${el.id}`} params={{ id: el.id }}>
                <img
                  src={el.image[0]}
                  alt="img casa"
                  className="w-full h-[200px] xl:h-[250px] 2xl:h-[300px] object-cover"
                />
              </RouterLink>
              <p className="text-[#105167] h-[10%] text-l xl:text-2xl 2xl:text-3xl pt-4">
                {el.titulo}
              </p>
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
  </div>
  <button className="fixed bottom-4 right-4 bg-texto-light text-main-light py-2 px-4 rounded-full shadow-md hover:bg-texto-dark z-10" onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
</div>
  );
};

export default Alquiler;
