import React, { useEffect, useState } from 'react'
import DashPreviewCard from './DashPreviewCard'
import { getAllProps } from '../redux/action'
import { useDispatch, useSelector } from 'react-redux'

const DashPropiedades = () => {
 
  const [props, setProps] = useState([])
  const llamada = useSelector(state => state.allprops)
  
  useEffect(() => {
    setProps([...llamada])
  },[llamada])

  return (
    <section>
      {props?.map((data, index) => (       
        <DashPreviewCard data={data} key={index}/>
      ))}
    </section>
  )
}

export default DashPropiedades
