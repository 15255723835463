const initialState = {
  theme: "light",
  props: [],
  allprops: [],
  propsventa: [],
  propsId: [],
  image: [],
  lat:"",
  long:"",
  dashid:"",
  dashUser:"",
};

export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_THEME": {
      let root = document.getElementById("root");
      if (action.payload === "light") {
        root.classList.remove("dark");
        return { ...state, theme: "light" };
      } else {
        root.classList.add("dark");
        return { ...state, theme: "dark" };
      }
    }
    case "DASH_DEL_DETAILS":
                return{
                    ...state,
                    propsid: "",
                    allprops:[],
                }
    case "GET_PROPS_ID":
      return {
        ...state,
        propsId: action.payload,
        image: action.payload.image.map((e) => e),
      };
      case "DASH_ID":
            return{
                ...state,
                dashid:action.payload
            }
      case "GET_DASHUSER":
            return{
                ...state,
                dashUser:action.payload
            }
            case "POSTADDPRODUCT":
            return{
                ...state
            }
    case "GET_PROPS":
      return {
        ...state,
        props: action.payload,
      };
    case "GET_ALL_PROPS":
      return {
        ...state,
        allprops: action.payload,
      };
    case "GET_PROPS_VENTA":
      return {
        ...state,
        propsventa: action.payload,
      };
    case "LATLONG":
      return {
        ...state,
        lat: action.payload[0].lat,
        long: action.payload[0].lon
      };
    default:
      return {
        ...state,
      };
  }
};
