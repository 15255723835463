import React from "react";
import bg from "../assets/fondohome.webp";
import { useAuth0 } from "@auth0/auth0-react";

// const Home = () => {
//   return (
//     <div name="home" className="w-full h-screen text-texto-light bg-main-light dark:text-texto-dark dark:bg-main-dark flex flex-col justify-center items-center">
//   <div className='relative inline-block text-center w-full h-[80%]'>
//   <img className="h-[100%] w-full" src={bg} alt="fondo" />
//   <div className='text-[#ffffff] text-lg md:text-7xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' > <br/> AMARCA <br/>
//     Desarrollos Inmobiliarios
//   </div>
//   </div>
//   <div className="dark:bg-main-dark h-[20%] flex flex-col justify-center items-center">
//     <p className="text-lg md:text-4xl py-4 md:py-8 max-w-md dark:bg-main-dark text-center">
//       Somos una empresa familiar.
//       Idóneos y con alta experiencia en el rubro inmobiliario.
//     </p>
//   </div>
// </div>
//   )
// }
const Home = () => {
  const { isAuthenticated, user } = useAuth0();

  console.log(isAuthenticated)
  console.log(user)

  return (
    <div
      name="home"
      className="w-full h-screen text-texto-light bg-main-light dark:text-texto-dark dark:bg-main-dark flex flex-col justify-center items-center"
    >
      <div className="relative inline-block text-center w-full h-[40%] 2xl:h-[60%]" >
        <img className="h-[100%] w-full" src={bg} alt="fondo" />
        <div className="text-[#ffffff] text-5xl md:text-7xl xl:text-6xl 2xl:text-[80px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:text-center">
          <br /> AMARCA <br />
          Desarrollos Inmobiliarios
        </div>
      </div>
      <div className="relative inline-block h-[10%] text-center w-full">
        <div className="bg-texto-light h-[8px] w-[60%]  absolute  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "></div>
      </div>
      <div className="dark:bg-main-dark h-[40%] pt-6 bg-main-light w-[80%] flex flex-col justify-center items-center">
        <p className="text-lg md:text-3xl pb-4 md:pb-8  xl:text-4xl 2xl:text-[46px]  dark:bg-main-dark text-center">
          Somos una empresa familiar con más de 20 años de experiencia en mundo
          inmobiliario. Estamos comprometidos con nuestros clientes y nos
          enorgullece ofrecerles un servicio de calidad respaldado por nuestro
          amplio conocimiento en el rubro. Nuestro equipo altamente capacitado y
          profesional es idóneo para brindarles soluciones a medida,
          garantizando su satisfacción en cada paso del camino.
        </p>
      </div>
    </div>
  );
};

export default Home;
