import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Administración from "../componentes/Administracion";
import Tasacion from "../componentes/Tasacion";
import Alquiler from "../componentes/Alquiler";
import Details from "../componentes/Details";
import Venta from "../componentes/Venta";
import Home from "../componentes/Home";
import Servicios from "../componentes/Servicios";
import Nosotros from "../componentes/Nosotros";
import Contacto from "../componentes/Contacto";
import Dashboard from "../componentes/Dashboard";


const Rutas = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route exact path={"/"} element={<Home />} />
          <Route exact path={"/Servicios"} element={<Servicios />} />
          <Route exact path={"/Nosotros"} element={<Nosotros/>} />
          <Route exact path={"/Contacto"} element={<Contacto />} />
          <Route exact path={"/Alquiler"} element={<Alquiler />} />
          <Route exact path={"/Venta"} element={<Venta />} />
          <Route exact path={"/Venta/:id"} element={<Details />} />
          <Route exact path={"/Alquiler/:id"} element={<Details />} />
          <Route exact path={"/Administracion"} element={<Administración />} />
          <Route exact path={"/Tasacion"} element={<Tasacion />} />
          <Route exact path={"/Dashboard"} element={<Dashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Rutas;
