import React, { useState } from 'react';
import { Image } from 'cloudinary-react';



const CloudinaryImageUploader = ({ secureURLs, setSecureURLs }) => {

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const uploaders = Array.from(files).map((file) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', `${process.env.REACT_APP_PRESET}`);

      return fetch(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/upload`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) =>          
          `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDNAME}/image/upload/s--00AaFEO8--/t_webp/${data.public_id}`


        //   return fetch(
        //`https://res.cloudinary.com/dtncqjwgn/image/upload/s--JhV20A72--/c_scale,h_650,w_890/${data.public_id}`)     
        //     .then((response) => response.json())
        //     .then((resizedData) => resizedData.secure_url
        //     ); 
        // });
        
        )});
  Promise.all(uploaders)
  .then((newSecureURLs) => {
    // Actualiza el estado de la URL segura en el componente padre
    setSecureURLs((prevSecureURLs) => [...prevSecureURLs, ...newSecureURLs]);
  })
  .catch((error) => console.error('Error al subir imágenes:', error.toString()));
};

  return (
    <div>
      <input type="file" multiple onChange={handleImageUpload} />
      {secureURLs.map((url, index) => (
        <div key={index}>
          <Image cloudName={process.env.REACT_APP_CLOUDNAME} publicId={url} width="150" crop="scale" />
        </div>
      ))}
    </div>
  );
};

export default CloudinaryImageUploader;