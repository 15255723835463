import React from "react";
import bg from "../assets/Contacto.webp";
import { FaInstagram, FaFacebook, FaWhatsapp } from "react-icons/fa";


const Contacto = () => {
  return (
    <div
    name="cont"
    className="w-full h-screen mt-14 text-texto-light bg-main-light dark:text-texto-dark dark:bg-main-dark relative overflow-hidden"
  >
      <div className="w-full h-[20%] md:h-[60%] xl:h-[55%] relative">
        <img
          className="w-full h-full object-cover"
          src={bg}
          alt="fondo"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#ffffff] text-4xl md:text-7xl 2xl:text-8xl text-center">
          <span >CONTACTO</span>
        </div>
      </div>

    <div className="dark:bg-main-dark flex flex-col items-center justify-center space-y-4 md:flex-row md:items-stretch">
      <div className="h-[20%] md:h-[300px] pt-4 xl:h-[250px] 2xl:h-[350px] md:w-2/4">
        <iframe
          title="mapa"
          width="100%"
          height="100%"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1474.6134084715122!2d-65.7797714255656!3d-28.472272430133902!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942428c0fd098261%3A0xfb8c288f3eeb7d17!2sA.MAR.CA.%20Desarrollos%20Inmobiliarios!5e0!3m2!1ses!2sar"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="md:w-1/4 max-w-md">
        <p className="text-xl md:text-4xl xl:text-3xl 2xl:text-4xl dark:bg-main-dark">
          amarca834@gmail.com <br />
          Maipú 834 <br />
          383 - 4856340
        </p>
        <div className="flex flex-row space-x-4 md:space-x-2 pt-4 justify-center">
          <a href="https://www.instagram.com/amarcainmobiliaria/" target="_blank">
            <FaInstagram size={30} />
          </a>
          <a href="https://www.facebook.com/amarca.inmobiliaria?locale=es_L" target="_blank">
            <FaFacebook size={30} />
          </a>
          <a href="https://wa.me/3834615748" target="_blank">
            <FaWhatsapp size={30} />
          </a>
        </div>
      </div>
      <div className="md:text-2xl md:w-1/4 max-w-xl flex flex-col justify-center items-center p-3 dark:bg-main-dark">
      <form action={process.env.REACT_APP_EMAIL} method="POST" >
          <div className="mb-2">
            <input
              type="text"
              id="nombre"
              name="nombre"
              className="p-1 w-full"
              placeholder="Nombre y Apellido"
            />
          </div>
          <div className="mb-2">
            <input
              type="email"
              id="email"
              name="email"
              className="p-1 w-full"
              placeholder="Email"
            />
          </div>
          <div className="mb-2">
            <textarea
              id="mensaje"
              name="mensaje"
              placeholder="Mensaje"
              className="p-2 w-full resize-none"
            ></textarea>
          </div>
          <button
            type="submit"
            className="text-[#FFFFFF] bg-[#105167] rounded-full px-4 py-2 mt-4"
          >
            Contactar
          </button>
        </form>
      </div>
    </div>
  </div>
  );
};

export default Contacto;
