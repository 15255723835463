import axios from "axios";


const URL = "/" ;

export function getProps() {
  return async function (dispatch) {
    var json = await axios.get(`${URL}` + "props");
    return dispatch({
      type: "GET_PROPS",
      payload: json.data,
    });
  };
}
export function getAllProps() {
  return async function (dispatch) {
    var json = await axios.get(`${URL}` + "allProps");
    return dispatch({
      type: "GET_ALL_PROPS",
      payload: json.data,
    });
  };
}
export function DashDelDetail(){
  return  function (dispatch){
    return dispatch({
      type: "DASH_DEL_DETAILS",
      // type: types.DASH_ID,
    });
  }
}
export const dashId=(payload)=>{
  console.log("payload", payload)
  return{
    type: "DASH_ID",
    payload:payload
  }
}
export function getPropsVenta() {
  return async function (dispatch) {
    var json = await axios.get(`${URL}` + "propsVenta");
    return dispatch({
      type: "GET_PROPS_VENTA",
      payload: json.data,
    });
  };
}
export const postAddPropiedad = (payload) => {
    
  return async function (dispatch) {
    try {
      var json = await axios.post(`${URL}Dashboard`, payload);
      console.log(json)
      return {

        type:"POSTADDPRODUCT",
        payload:json.data
      }
    } catch (error) {
      console.log(error);
    }
  };

}

export function getPropById(id) {
  return async function (dispatch) {
    var json = await axios.get(`${URL}` + `${id}`);
    return dispatch({
      type: "GET_PROPS_ID",
      payload: json.data,
    });
  };
}

export function latlong(ubicacion){
  return async function (dispatch){
    var json = await axios.get(`https://us1.locationiq.com/v1/search?key=pk.f07af5b149d93bd644ac2082fb4c0566&q=${ubicacion}&format=json`);
    return dispatch ({
      type: "LATLONG",
      payload: json.data,
    })
  }
}

export function getDashUser(email){
  return async function (dispatch){
    try {
      let response= await axios.get(`${URL}userdata?email=${email}`)
      return dispatch({
        type:"GET_DASHUSER",
        payload:response.data.profile
      })
    } catch (error) {
      console.log(error)
    }
  }
}
export const createnewuser = (payload) => {
  return async function (dispatch) {
    try {
      var json = await axios.post(`${URL}user`, payload);
      return json
    } catch (error) {
      console.log("error",error);
    }
  };

}

export const changeTheme = (payload) => {
  return {
    type: "CHANGE_THEME",
    payload: payload,
  };
};
