import React from "react";
import andrea from "../assets/Andrea.webp";
import ana from "../assets/Ana.webp";

const Nosotros = () => {
  return (
    <div
      name="nos"
      className="w-full min-h-screen py-16 px-4 md:px-8 lg:px-16 bg-texto-light dark:bg-main-dark flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8"
    >
      <div className="md:w-1/2  flex flex-col justify-between bg-opacity-50 h-auto md:h-[750px] max-w-xl p-4 xl:h-[580px] md:p-6 bg-main-light text-texto-light dark:bg-main-dark">
        <div>
          <h1 className="text-4xl md:text-5xl xl:text-4xl dark:bg-main-dark text-[#000000]">
            Misión
          </h1>
          <p className="text-lg md:text-xl xl:text-lg text-[#FFFFFF] dark:text-texto-dark">
            Brindar servicios inmobiliarios de alta calidad, mediante nuestra
            amplia experiencia y trayectoria en el rubro. Estamos comprometidos
            con la satisfacción de nuestros clientes, a través del asesoramiento
            personalizado, priorizando dar confianza y seguridad en nuestra
            institución.
          </p>
        </div>
        <div>
          <h1 className="text-4xl xl:text-4xl md:text-5xl dark:bg-main-dark text-[#000000]">
            Visión
          </h1>
          <p className="text-lg md:text-xl xl:text-lg text-[#FFFFFF] dark:text-texto-dark">
            Ser una institución idónea y en constante adaptación a las
            necesidades del mercado inmobiliario y desarrollista.
          </p>
        </div>
        <div>
          <h1 className="text-4xl md:text-5xl xl:text-4xl dark:bg-main-dark text-[#000000]">
            Valores
          </h1>
          <p className="text-lg  md:text-xl xl:text-lg text-[#FFFFFF] dark:text-texto-dark">
            Nuestra institución descansa sobre los valores de la
            responsabilidad, honestidad, respeto, perseverancia y confianza.
          </p>
        </div>
        <div>
          <h1 className="text-4xl md:text-5xl xl:text-4xl dark:bg-main-dark text-[#000000]">
            Historia
          </h1>
          <p className="text-lg md:text-xl xl:text-lg text-[#FFFFFF] dark:text-texto-dark">
            Somos una empresa familiar fundada en el año 2000, en Catamarca,
            Argentina. Contamos con 23 años de experiencia en servicios de
            bienes raíces.
          </p>
        </div>
      </div>
      <div className="md:w-1/2 bg-opacity-50 h-auto xl:h-[580px] md:h-[750px] w-[380px] max-w-xl p-4 md:p-4 bg-main-light text-texto-light dark:bg-main-dark">
        <div className="p-4 md:p-6">
          <img
            className="mx-auto mb-4 w-36 h-36 rounded-full"
            src={ana}
            alt="ana"
          />
          <h3 className="mb-1 text-2xl text-[#000000] text-center">
            Ana Maria Cano
          </h3>
          <h3 className="mb-1 text-2xl text-[#ffffff] text-center">
            CEO & Fundadora de AMARCA
          </h3>
        </div>
        <div className="p-4 md:p-8">
          <img
            className="mx-auto mb-4 w-36 h-36 rounded-full"
            src={andrea}
            alt="Andrea"
          />
          <h3 className="mb-1 text-2xl text-[#000000] text-center">
            Andrea Pereyra
          </h3>
          <h3 className="mb-1 text-2xl text-[#FFFFFF] text-center">
            Socia Principal de AMARCA
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Nosotros;
// grid justify-items-center m-[80px] bg-opacity-50 h-[750px] w-[600px]  bg-main-light
