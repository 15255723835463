import React, { useState, useEffect } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link as RouterLink } from "react-router-dom";
import logo from "../assets/logo.webp";


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  
  const closeMenu = () => {
    setIsOpen(false);
  };
  


  var styles = {
    bmMenu: {
      background: "rgba(16, 81, 103, 0.9)",
    },
    bmItem: {
      color: "#ffffff",
      padding: "20px",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.7)",
    },
  };

  return (
    <nav className="bg-main-light text-texto-light md:h-[60px] lg:h-[60px] dark:bg-main-dark dark:text-texto-dark fixed w-full z-20 top-0 left-0">
      <div className="flex flex-wrap justify-between items-center w-full">
          <RouterLink to="/">
        <div className="flex items-center">
          <img src={logo} className="mr-2 h-[60px] sm:h-[60px]" alt="Logo" />
          <span>
            AMARCA
            </span>
        </div>
          </RouterLink>
        <div className="md:hidden">
          <button
            className="text-2xl text-white p-2"
            onClick={() => setIsOpen(!isOpen)}
          >
            ☰
          </button>
        </div>
        <div className="hidden justify-between items-center w-full md:flex md:w-auto md:h-[60px]  md:order-1">
          <ul className="flex flex-col p-4 mt-4 bg-main-light md:flex-row md:space-x-8 md:mt-0 md:border-0 md:h-[60px]  dark:bg-main-dark md:dark:bg-main-dark">
            <li>
              <RouterLink to="/">
                <a
                  href=""
                  className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:text-2xl md:p-0"
                >
                  Inicio
                </a>
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/Servicios">
                <a
                  href=""
                  className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:text-2xl md:p-0"
                >
                  Servicios
                </a>
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/Nosotros">
                <a
                  href=""
                  className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:text-2xl md:p-0"
                >
                  Nosotros
                </a>
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/Contacto">
                <a
                  href=""
                  className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:text-2xl md:p-0"
                >
                  Contacto
                </a>
              </RouterLink>
            </li>
            
          </ul>
        </div>
      </div>
      <Menu
        styles={styles}
        right
        width={"100%"}
        isOpen={isOpen}
        onStateChange={({ isOpen }) => setIsOpen(isOpen)}
        customCrossIcon={false}
        customBurgerIcon={false}
        // customBurgerIcon={<span>☰</span>}
      >
        <RouterLink onClick={closeMenu} to="/">
          Inicio
        </RouterLink>

        <RouterLink onClick={closeMenu} to="/Servicios">
          Servicios
        </RouterLink>

        <RouterLink onClick={closeMenu} to="/Nosotros">
          Nosotros
        </RouterLink>

        <RouterLink onClick={closeMenu} to="/Contacto">
          Contacto
        </RouterLink>
      </Menu>
    </nav>
  );
};

export default Navbar;
