import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button className='border rounded-md mt-96 h-12 w-40 font-bold 
  hover:bg-verde-light 
  hover:text-main-light 
  hover:border-verde-light 
  text-main-light bg-texto-light'  onClick={() => loginWithRedirect()}>Ingresar</button>;
};