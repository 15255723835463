import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { DashDelDetail, createnewuser, getDashUser, postAddPropiedad } from "../redux/action";
import { LoginButton } from "./Login";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import Cloudinary from "./Cloudinary";
import axios from "axios";
import {Typography} from "@material-tailwind/react";
import { GrDocumentUpload} from 'react-icons/gr'
import { func } from "prop-types";
import ModificarPropiedad from "./ModificarPropiedad";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user, isLoading } = useAuth0();
  const email = user?.email;
  const [mod, setMod] = useState(true)
  const [agr, setAgr] = useState(false)
  const [userProfile, setUserProfile] = useState(null);
  const dashuser = useSelector((state) => state.dashUser);
  const [errorImg, setErrorImg] = useState(false);
  const [errors, setErrors] = useState(false);
  console.log("email", email);
  const  [imageSelected , setImageSelected] = useState([])
  const [value, setValue ] = useState(false)
  const [secureURLs, setSecureURLs] = useState([]);
  

  
  const [addPropiedades, setAddPropiedades] = useState({
    titulo: "",
    tipo: "",
    ubicacion: "",
    venta: true,
    alquiler: true,
    ambientes: 0,
    image: [],
    superficie: 0,
    superficiecubierta: 0,
    baños: 0,
    dormitorios: 0,
    cochera: true,
  });
  function validate(addPropiedades) {
    let errors = {};

    // if (addPropiedades.titulo.length > 50) {
    //   errors.titulo = "Inserte un titulo menor a 50 caracteres";
    // }
    // if (addPropiedades.titulo === "") {
    //   errors.titulo = "Inserte un titulo para la propiedad";
    // } else if (
    //   !/^[a-zA-Z0-9ÁÉÍÓÚáéíóúñÑ\u002F\s]*$/.test(addPropiedades.titulo)
    //   ) {
    //     errors.titulo =
    //     "El titulo solo puede contener letras, números, espacios y barras";
    //   }
    //   if (addPropiedades.tipo.length > 15) {
    //     errors.tipo = "Inserte un nombre de tipo menor a 15 caracteres";
    //   }
    //   if (addPropiedades.tipo === "") {
    //     errors.tipo = "Inserte un tipo para la propiedad";
    //   } else if (!/^[a-zA-ZÁÉÍÓÚáéíóúñÑ\u002F\s]*$/.test(addPropiedades.tipo)) {
    //     errors.tipo = "El nombre del tipo no puede contener caracteres o números";
    //   }
    //   if (addPropiedades.ubicacion.length > 50) {
    //     errors.ubicacion = "Inserte una ubicacion menor a 50 caracteres";
    //   }
    //   if (addPropiedades.ubicacion === "") {
    //     errors.ubicacion = "Inserte la ubicacion de la propiedad";
    //   } else if (
    //     !/^[a-zA-Z0-9ÁÉÍÓÚáéíóúñÑ\u002F\s]*$/.test(addPropiedades.ubicacion)
    //     ) {
    //       errors.ubicacion =
    //       "La ubicacion de la propiedad solo puede contener letras, números, espacios y barras";
    //     }
    //     if (
    //       addPropiedades.superficie < 0 ||
    //       addPropiedades.superficie === 1 ||
    //       !addPropiedades.baños ||
    //   !/^[0-9]+$/.test(addPropiedades.baños)
    // ) {
    //   errors.baños =
    //   "Solo se permiten baños desde 1 a 10 (solo valores numericos) ";
    // }
    // if (
    //   addPropiedades.baños < 0 ||
    //   addPropiedades.baños === 0 ||
    //   !addPropiedades.baños ||
    //   !/^[0-9]+$/.test(addPropiedades.baños)
    //   ) {
    //     errors.baños =
    //     "Solo se permiten baños desde 1 a 10 (solo valores numericos) ";
    //   }
    //   if (
    //     addPropiedades.baños < 1 ||
    //     addPropiedades.baños > 10 ||
    //     !addPropiedades.baños ||
    //     !/^[0-9]+$/.test(addPropiedades.baños)
    //     ) {
    //       errors.baños =
    //       "Solo se permiten baños desde 1 a 10 (solo valores numericos) ";
    //     }
    //     if (
    //       addPropiedades.ambientes > 1 ||
    //       addPropiedades.ambientes < 10 ||
    //       !/^[0-9]+$/.test(addPropiedades.ambientes)
    //       ) {
    //         errors.ambientes =
    //         "Solo se permiten ambientes desde 1 a 10 (solo valores numericos) ";
    //       }
          return errors;
        }

        function handleBoolean(e) {
          const value = e.target.value === "true";
          setValue(value)
          setAddPropiedades({
          ...addPropiedades,
          [e.target.name]: value

          })
        }
        
        function handleChange(e) {
          
          setAddPropiedades({
            ...addPropiedades,
            [e.target.name]: e.target.value,
          });
          setErrors(
            validate({
              ...addPropiedades,
              [e.target.name]: e.target.value,
            })
            );
          }

          addPropiedades.image = secureURLs
          
          function postPropiedad() {
            if(addPropiedades.image.length === 0){
              setErrorImg(!errorImg)
            }
            if (
              errors.titulo ||
              errors.tipo ||
              errors.baños ||
              errors.ambientes ||
              errors.dormitorios ||
              errors.superficie ||
              errors.superficiecubierta ||
              errors.ubicacion 
              ) {
                swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error, corrija los errores!",
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        {console.log(addPropiedades)}
        
        dispatch(postAddPropiedad(addPropiedades));
        swal.fire({
          position: "top-end",
          icon: "success",
          title: "Producto agregado !",
          showConfirmButton: false,
          timer: 1000,
        });
        setAddPropiedades({
          titulo: "",
          tipo: "",
          ubicacion: "",
          venta: true,
          alquiler: true,
          ambientes: 0,
          image: [],
          superficie: 0,
          superficiecubierta: 0,
          baños: 0,
          dormitorios: 0,
          cochera: true,
        });
        // window.location.reload()
        dispatch()
        
      }
    }
    function handleAgr(){
      dispatch(DashDelDetail())
      setAgr(!agr)
      setMod(!mod)
    }

      function handleMod(){
        setMod(mod)
        setAgr(!agr)
        }
    useEffect(() => {
      if (isAuthenticated && !isLoading) {
        console.log(isAuthenticated);
        console.log(isLoading);
        let bodydepost = {
          name: user.name,
          email: email,
        };
        dispatch(createnewuser(bodydepost));
      }
      if (isAuthenticated && !isLoading) {
        // Accede al perfil del usuario autenticado
        setUserProfile(user);
      dispatch(getDashUser(email));
    }
  }, [isAuthenticated, user, email, isLoading]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  console.log("window", window.location.href);
  console.log("AGR", agr);
  console.log("MOD", mod);
  console.log("userAdmin", dashuser);
  console.log("isAuth", isAuthenticated);

  return isAuthenticated ? (
    <>
      {dashuser === "Admin" && (
        <div className="bg-[#E0FBFC] h-[100%] w-full pt-20 flex flex-cols justify-between">
          <div className="w-[20%] h-fit border rounded-lg ">
            <ul className="flex flex-col items-center">
              <li className="mx-3.5 mt-4 mb-2" onClick={() => setAgr(true)} >
                {/* <input
                                 
                                  type="radio"
                                  className="hidden peer"
                                  required = ""
                                /> */}
                                 <Typography
                  variant="small"

                  className="font-black uppercase opacity-75"
                >
                  Cargar Propiedad
                </Typography></li>
                <li className="bg-texto-light h-[3px] w-[80%] "></li>
              <li className="mx-3.5 mt-4 mb-2" onClick={() => setAgr(false)} >
                {/* <input  type="radio" className="hidden peer" required = "" /> */}
                 <Typography
                  variant="small"

                  className="font-black uppercase opacity-75"
                >
                  Modificar Propiedad
                </Typography></li>
              
            </ul>

          </div>
          
            {agr === true ? (  
            
              <section className="w-[80%] mx-10 px-10 mx-auto pt-10 bg-texto-light rounded-md shadow-md">
            <h1 className="text-xl font-bold text-main-light capitalize dark:text-white">
              Carga de propiedades
            </h1>
            <form>
              <div className="grid grid-cols-1 gap-4 mt-4 2xl:grid-cols-2">
                <div>
                  <label
                    className="text-main-light dark:text-gray-200"
                  >
                    Titulo
                  </label>
                  <input
                    id="titulo"
                    requiered
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring "
                    onChange={(e) => handleChange(e)}
                    name="titulo"
                    value={addPropiedades.name}
                  />
                  {errors?.name && (
                    <p className="text-[15px] text-[red]">{errors.titulo}</p>
                  )}
                </div>

                <div>
                  <label className="text-main-light dark:text-gray-200">
                    Ubicacion
                  </label>
                  <input
                    id="ubicacion"
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    name="ubicacion"
                    value={addPropiedades.ubicacion}
                    onChange={(e) => handleChange(e)}
                  />
                  {errors?.ubicacion && (
                    <p className="text-[15px] text-[red]">{errors.ubicacion}</p>
                  )}{" "}
                </div>
                <div>
                  <label
                    className="text-main-light dark:text-gray-200"
                  >
                    Tipo
                  </label>
                  <input
                    id="tipo"
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    name="tipo"
                    value={addPropiedades.name}
                    onChange={(e) => handleChange(e)}
                  />
                  {errors?.name && (
                    <p className="text-[15px] text-[red]">{errors.tipo}</p>
                  )}
                </div>
                
                <div>
                  <label
                    className="text-main-light dark:text-gray-200"
                  >
                    Dormitorios
                  </label>
                  <input
                    id="dormitorios"
                    type="number"
                    min="1"
                    max= "10"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    name="dormitorios"
                    value={addPropiedades.dormitorios}
                    onChange={(e) => handleChange(e)}
                  />
                  {errors?.dormitorios && (
                    <p className="text-[15px] text-[red]">{errors.dormitorios}</p>
                  )}
                </div>
                <div>
                  <label
                    className="text-main-light dark:text-gray-200"
                  >
                    Ambientes
                  </label>
                  <input
                    id="ambientes"
                    type="number"
                    min="1"
                    max= "10"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    name="ambientes"
                    value={addPropiedades.ambientes}
                    onChange={(e) => handleChange(e)}
                  />
                  {errors?.ambientes && (
                    <p className="text-[15px] text-[red]">{errors.ambientes}</p>
                  )}
                </div>
                <div>

                  <label
                    className="text-main-light dark:text-gray-200"
                    >
                    Baños
                  </label>
                  <input
                    id="baños"
                    type="number"
                    min="1"
                    max= "10"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    name="baños"
                    value={addPropiedades.name}
                    onChange={(e) => handleChange(e)}
                    />
                  {errors?.baños && (
                    <p className="text-[15px] text-[red]">{errors.baños}</p>
                    )}
                  </div>
              

                <div>
                  <label
                    className="text-main-light dark:text-gray-200"
                  >
                    Superficie
                  </label>
                  <input
                    id="superficie"
                    type="number"
                    min = "0"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    name="superficie"
                    value={addPropiedades.superficie}
                    onChange={(e) => handleChange(e)}
                  />
                  {errors?.superficie && (
                    <p className="text-[15px] text-[red]">{errors.superficie}</p>
                  )}
                </div>
                <div>
                  <label
                    className="text-main-light dark:text-gray-200"
                  >
                    Superficie Cubierta
                  </label>
                  <input
                    id="superficiecubierta"
                    type="number"
                    min = "0"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    name="superficiecubierta"
                    value={addPropiedades.superficiecubierta}
                    onChange={(e) => handleChange(e)}
                  />
                  {errors?.superficiecubierta && (
                    <p className="text-[15px] text-[red]">{errors.superficiecubierta}</p>
                  )}
                </div>
                <div>
                  <label
                    className="text-main-light dark:text-gray-200" htmlFor="booleanSelect"
                  >
                    Alquiler
                  </label>
                  <select
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    onChange={(e) => handleBoolean(e)}
                    name="alquiler"
                    >
                    {console.log("alquiler", addPropiedades.alquiler)}
                    <option value="true">Si</option>
                    <option value="false" >No</option>
                  </select>
                </div>
                <div>
                  <label
                    className="text-main-light dark:text-gray-200"
                    htmlFor="booleanSelect"
                  >
                    Venta
                  </label>
                  <select
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    onChange={(e) => handleBoolean(e)}
                    name="venta"
                    
                    >
                    {console.log("venta", addPropiedades.venta)}
                    <option value="true">Si</option>
                    <option value="false" >No</option>
                  </select>
                </div>
                <div>
                  <label
                    className="text-main-light dark:text-gray-200 " htmlFor="booleanSelect"
                  >
                    Cochera
                  </label>
                  <select
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    onChange={(e) => handleBoolean(e)}
                    name="cochera"
                    
                  >
                    {console.log("cochera", addPropiedades.cochera)}
                   <option value="true">Si</option>
                    <option value="false" >No</option>
                  </select>
                </div>
                
              </div>
                <div>
                  <label className="block text-sm font-medium text-main-light">
                    Imagenes
                  </label>
                  {/* <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-main-light  border-dashed rounded-md">
  <div className="space-y-1 text-center">
  <svg className="mx-auto h-12 w-12 text-main-light " stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
  <div className="flex text-sm text-main-light ">
  <label for="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
  <span className="">Upload a file</span>
  <input id="file-upload" name="file-upload" type="file" className="sr-only"/>
  </label>
  <p className="pl-1 text-main-light ">or drag and drop</p>
  </div>
  <p className="text-xs text-main-light ">
  PNG, JPG, GIF up to 10MB
  </p>
  </div>
  </div> */}
                  <div>
                  <Cloudinary secureURLs={secureURLs} setSecureURLs={setSecureURLs}  />
                  {console.log("image", addPropiedades.image)}
                  {console.log("propiedades", addPropiedades)}
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button className="px-6 py-2 leading-5 text-main-light transition-colors duration-200 transform bg-pink-500 rounded-md hover:bg-pink-700 focus:outline-none focus:bg-gray-600" 
                  onClick={postPropiedad} >
                    Cargar
                  </button>
                </div>
            </form>
          </section>):(
            <section className="flex flex-col justify-center items-center">
            <ModificarPropiedad/>
            </section>
          )}
        </div>
      )}{" "}
      :
      {dashuser === "Client" && (
        <main class="h-screen w-full flex flex-col justify-center items-center bg-main-dark ">
          <h1 class="text-9xl font-extrabold text-main-light tracking-widest">
            404
          </h1>
          <div class="bg-verde-light px-2 text-sm rounded rotate-12 absolute">
            You don't have permission to use this page
          </div>
          <button class="mt-5">
            <a class="relative inline-block text-sm font-medium text-verde-light  group active:text-main-dark focus:outline-none focus:ring">
              <span class="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-verde-light group-hover:bg-verde-light   group-hover:translate-y-0 group-hover:translate-x-0"></span>

              <span class="relative block px-8 py-3 bg-main-dark hover:bg-main-light hover:text-main-dark   border border-current">
                <Link to="/">Go Home</Link>
              </span>
            </a>
          </button>
        </main>
      )}
    </>
  ) : (
    <LoginButton />
  );
};

export default Dashboard;
